import { AxfoodApi } from '@occ/api-client';
import type { AddToCartForm } from '@occ/api-client';

const axfoodApi = new AxfoodApi({
  baseUrl: ''
})

export const getCart = async () => {
  const { data } = await axfoodApi.getCartUsingGet();
  return data;
};

export const addToCart = async (products: Array<AddToCartForm>, storeId?: string) => {
    const response = await axfoodApi.addToCartUsingPost({ products, storeId });
    return response.data;
}