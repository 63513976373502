import { setDeliveryActiveStoreAndCosts } from '@actions/delivery';
import { setLoginAlert } from '@actions/login';
import useCart from '@features/cart/useCart';
import getActiveStore from '@api/getActiveStore';
import axfoodAppToWebAPI from '@api/nativeapp/appToWebApi';
import webToAppApi from '@api/nativeapp/webToApp';
import { CUSTOMER_NOT_LOGGED_IN, LOGIN_CONFIRM } from '@features/login/constants';
import { isCsrfBadOrmissing } from '@helpers/error';
import logError from '@helpers/logHelpers';
import { useAppDispatch } from '@hooks/redux';
import useAppRouter from '@hooks/useAppRouter';
import axios, { isAxiosError } from 'axios';
import { useEffect, useRef } from 'react';
import useCustomer from './useCustomer';

const useFetchInitialData = () => {
  const dispatch = useAppDispatch();
  const router = useAppRouter();
  const isMounted = useRef(false);
  const { customerFetchIsRequired } = useCustomer();
  const { refreshCart } = useCart();
  useEffect(() => {
    isMounted.current = true;
    axios.interceptors.response.use(
      (res) => {
        return res;
      },
      (error) => {
        // console.log('axios interceptor log errored request response', error); // uncomment for debugging purposes
        if (isAxiosError(error)) {
          switch (error?.response?.status) {
            case 401:
            case 403:
              if (!isCsrfBadOrmissing(error)) {
                if (error.response.data.customerLoggedIn === 'false') {
                  return dispatch(setLoginAlert(CUSTOMER_NOT_LOGGED_IN));
                }
                dispatch(setLoginAlert(LOGIN_CONFIRM));
              }
              break;
            default:
              break;
          }
        }
        return Promise.reject(error);
      }
    );

    if (customerFetchIsRequired) {
      Promise.all([getActiveStore()])
        .then(([ { data: storeData }]) => {
          dispatch(setDeliveryActiveStoreAndCosts(storeData));
        })
        .catch((error) => {
          if (isAxiosError(error)) {
            logError('Failed to fetch initial data', error);
          }
        });
      (window as any).axfoodAppToWebAPI = axfoodAppToWebAPI(dispatch, router, refreshCart);
      webToAppApi.eventWebReady();
    }

    return () => {
      isMounted.current = false;
    };
  }, [customerFetchIsRequired]);
};

export default useFetchInitialData;
