import { AxfoodApi, CustomerExtraData } from '@occ/api-client';

/**
 * CartApi
 */
const axfoodApi = new AxfoodApi({
  baseUrl: ''
})

export const getCart = () => axfoodApi.getCartUsingGet();

export const getMostLikelyAddress = async () => {
  const result = await axfoodApi.getCustomerLikelyDeliveryAddressUsingGet();
  return result.data;
};

export const addVoucher = (voucherCode: string) => axfoodApi.applyVoucherToCartUsingPost({ voucherCode });

export const deleteCart = (isReplacingOrder: boolean, signal: AbortSignal) =>
  axfoodApi.clearCartUsingDelete({ cancelPossibleContinueCart: isReplacingOrder}, { signal });

export const removeVoucher = (voucherCode: string) => axfoodApi.releaseVoucherFromCartUsingDelete(voucherCode);

export const setCustomerExtraInfo = ({ commentFromCustomer, usersOrderReference, doorCode }: CustomerExtraData) => {
  return axfoodApi.setCustomerExtraUsingPost({
    commentFromCustomer, doorCode, usersOrderReference
  });
};

export const setPostalCode = (postalCode: string) => {
  return axfoodApi.setPostalCodeUsingPost({ postalCode });
};

export const setDeliveryAddress = (
  firstName?: string,
  lastName?: string,
  addressLine1?: string,
  addressLine2?: string,
  postalCode?: string,
  town?: string,
  addressId?: string,
  email?: string,
  cellphone?: string,
  companyName?: string,
  longitude?: number,
  latitude?: number
) => {
  return axfoodApi.setDeliveryAddressUsingPost({
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    postalCode,
    town,
    addressId,
    email,
    cellphone,
    companyName,
    longitude,
    latitude,
  });
};

export const setCartCustomerInfo = (
  email: string,
  cellphone: string,
  firstName?: string,
  lastName?: string,
  saveToMyPages?: boolean
) => {
  return axfoodApi.setCustomerContactUsingPost({ email, cellphone, firstName, lastName, saveToMyPages });
};

export const openOrderForChange = (orderId: string) => {
  return axfoodApi.continueShopForOrderUsingPost({
    orderNumber: orderId,
  });
};

export const addOrderToCart = async (orderCode: string) => {
  const res = await axfoodApi.putToCartUsingPost1(orderCode);
  return res.data;
};

export const getCartStatus = (checkStock: boolean = false, slotCode?: string, storeId?: string) =>
  // checkStock needs to be undefined if not true (false is not allowed, at least for now)
  axfoodApi.checkCartStatusUsingGet({
    checkStock: checkStock ? true : undefined,
    slotCode,
    storeId,
  });
