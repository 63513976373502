/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { removeCartRecipe } from '@api';
import type { AxfoodCartViewModel, AxfoodRecipeEntryViewModel } from '@occ/api-client';
import Spinner from '@components/atoms/Spinner';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import getRecipeThumbnailUrl from '@helpers/getRecipeThumbnailUrl/getRecipeThumbnailUrl';
import useAppRouter from '@hooks/useAppRouter/useAppRouter';
import useConfig from '@hooks/useConfig';
import IconRemove from '@public/icons/regularIcons/icon-remove.svg';
import useCart from '@features/cart/useCart';
import { cartTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import IconButton from '../IconButton';
import { MINICART_EVENT_LIST } from '../MiniCartProductRow/MiniCartProductRow';
import {
  Container,
  ProductInfo,
  ProductInfoTopRow,
  ProductTitle,
  ProductTitles,
  Thumbnail,
  ThumbnailWrapper,
} from '../MiniCartProductRow/MiniCartProductRow.styles';

interface Props {
  recipe: AxfoodRecipeEntryViewModel;
}

const MiniCartRecipeRow = ({ recipe }: Props) => {
  const { t } = useTranslation('recipes');
  const [isRemovingRecipe, setIsRemovingRecipe] = useState(false);
  const { cart, refreshCart } = useCart();
  const recipeProducts = cart?.products?.filter(
    (p) => p.includedInRecipeList && p.includedInRecipeList[0] === recipe?.name
  ) || [];
  const router = useAppRouter();
  const { config } = useConfig();

  const url = `${config?.PUBLIC_HOST}/recept/${recipe?.id
    ?.replace('%', 'procent')
    .split(' ')
    .join('-')
    .replace('/', '-')}`;
  const thumbnailUrl = getRecipeThumbnailUrl(recipe);

  const removeRecipe = async () => {
    setIsRemovingRecipe(true);
    const response = await removeCartRecipe({ recipeId: recipe.id });
    if (response) {
      cartTracker.removeRecipeProducts(recipeProducts, MINICART_EVENT_LIST.recept);
      refreshCart(response.data as AxfoodCartViewModel);
    }
  };

  return (
    <Container $withBottomBorder>
      <ThumbnailWrapper onClick={() => router.push(url)} $withThumbnail={!!thumbnailUrl}>
        {thumbnailUrl && <Thumbnail src={thumbnailUrl} alt={recipe.name} data-testid="recipe-row-image" />}
      </ThumbnailWrapper>
      <ProductInfo>
        <ProductInfoTopRow>
          <ProductTitles onClick={() => router.push(url)}>
            <ProductTitle>
              <Paragraph size="lg" data-testid="recipe-row-title" truncateText>
                {recipe.name}
              </Paragraph>
              <Paragraph size="md" data-testid="recipe-row-sub-title">
                {recipe.portions} {t('servings')}
              </Paragraph>
            </ProductTitle>
          </ProductTitles>
          {isRemovingRecipe ? (
            <Spinner color="red" />
          ) : (
            <IconButton icon={IconRemove} data-testid="recipe-row-remove-icon" onClick={removeRecipe} />
          )}
        </ProductInfoTopRow>
      </ProductInfo>
    </Container>
  );
};

export default MiniCartRecipeRow;
