import { useAppSelector } from '@hooks/redux';
import usePrevious from '@hooks/usePrevious';
import useCart from '@features/cart/useCart';
import { selectActiveStoreId } from '@selectors/delivery';
import axios, { CancelToken } from 'axios';
import { useEffect, useRef } from 'react';

/**
 * @summary runs a function when active store id changes or if the pickup store
 * on the selected slot changes in the redux store. The slot for a store can have
 * a different pickup store for the case of express deliveries.
 * The backend will return the assortment for the pickup store on different calls,
 * like when getting products for a category.
 * Does not trigger on mount
 * @param callback
 */
const useStoreChange = (callback: (cancelToken: CancelToken) => Promise<void> | undefined | null) => {
  const isMounted = useRef(false);
  const activeStoreId = useAppSelector(selectActiveStoreId);
  const prevStoreId = usePrevious(activeStoreId);
  const { cart } = useCart();
  const slotPickupStoreId = cart?.slot?.pickupStoreId;
  const prevSlotPickupStoreId = usePrevious(slotPickupStoreId);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let isCancelled = false;
    if (isMounted.current) {
      const storeChanged = prevStoreId !== activeStoreId;
      const pickupStoreChanged = prevSlotPickupStoreId !== slotPickupStoreId;

      if (!isCancelled && !!callback && (storeChanged || pickupStoreChanged)) {
        callback(source.token);
      }
    } else {
      isMounted.current = true;
    }
    return () => {
      isCancelled = true;
      source.cancel('cancel update data on active store change');
    };
  }, [callback, activeStoreId, slotPickupStoreId]);
};

export default useStoreChange;
