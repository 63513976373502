import Icon from '@components/atoms/Icon';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const sizes = {
  small: {
    cardHeight: 303,
    imageHeight: 144,
    contentHeight: 159,
  },
  medium: {
    cardHeight: 383,
    imageHeight: 220,
    contentHeight: 163,
  },
};

interface VariantProps {
  $variant: 'small' | 'medium';
}

export const Container = styled.div<VariantProps>`
  width: 100%;
  background: ${({ theme }) => theme.colors.colorWhite};
  border-radius: ${rem(16)};
  display: flex;
  flex-direction: column;
  height: ${rem(sizes.small.cardHeight)}; // always small on mobile

  ${media.tabletPortrait} {
    height: ${({ $variant }) => rem(sizes[$variant].cardHeight)};
  }
`;

export const ImageWrapper = styled.div<VariantProps>`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: ${rem(16)} ${rem(16)} 0 0;
  height: ${rem(sizes.small.imageHeight)}; // always small on mobile

  ${media.tabletPortrait} {
    height: ${({ $variant }) => rem(sizes[$variant].imageHeight)};
  }
`;

export const Content = styled.div<VariantProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${rem(16)};
  height: ${rem(sizes.small.contentHeight)};

  ${media.tabletPortrait} {
    padding: ${rem(16)} ${rem(16)} ${rem(24)};
    height: ${({ $variant }) => rem(sizes[$variant].contentHeight)};
  }
`;

export const CardFooterContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PurchasableIcon = styled(Icon)`
  margin-left: auto;
`;
