
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { AxfoodReplacedOrderProductViewModel, OrderDataV2Response } from '@api/generated/storefront';
import { mapOrderRowFromNotDeliveredProduct, mapOrderRowFromPartlyDeliveredProduct, mapOrderRowFromReplacedProduct, mapOrderRowFromReplacementProduct, OrderRow, } from '../DetailOrderRow/mapOrderRow';
export interface DeliveryChanges {
    partiallyDeliveredProducts: OrderRow[];
    replacedProducts: OrderRow[];
    notDeliveredProducts: OrderRow[];
}
const mapReplacedProduct = (p: AxfoodReplacedOrderProductViewModel): OrderRow[] => {
    const replacementProducts: OrderRow[] = p.replacementProducts?.map(mapOrderRowFromReplacementProduct) || [];
    return [mapOrderRowFromReplacedProduct(p), ...replacementProducts];
};
const mapDeliveryChanges = (order: OrderDataV2Response): DeliveryChanges => ({
    partiallyDeliveredProducts: order.partiallyDeliveredProducts?.map(mapOrderRowFromPartlyDeliveredProduct) || [],
    replacedProducts: order.replacedProducts?.flatMap(mapReplacedProduct) || [],
    notDeliveredProducts: order.notDeliveredProducts?.map(mapOrderRowFromNotDeliveredProduct) || [],
});
export default mapDeliveryChanges;

    async function __Next_Translate__getStaticProps__19469593cf2__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'features/my/OrderDetailPage/components/DeliveryChangesSection/mapDeliveryChanges',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19469593cf2__ as getStaticProps }
  