import LinkArea from '@components/atoms/LinkArea';
import { Label } from '@components/atoms/Paragraph/Paragraph';
import CartIcon from '@public/icons/regularIcons/icon-cart.svg';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { ReactNode } from 'react';
import { CardFooterContent, Container, Content, ImageWrapper, PurchasableIcon } from './RecipeCard.styles';

export interface RecipeCardProps {
  name: string;
  subtitle: ReactNode;
  imageSrc?: string;
  imageAlt?: string;
  linkUrl: string;
  onClick: () => void;
  variant?: 'small' | 'medium';
  purchasable: boolean;
  'data-testid'?: string;
}
//TODO: Remove when migrated to the new design
/*
  In the old design, purchasable recipes had a splash image that was inserted directly in the url by cloudinary
  When we are fully migrated to the new design, we can remove this and just use the imageSrc as is
  */
const getImageUrlWithoutBuyRecipeSplash = (imageSrc: string) => {
  return imageSrc.replace(/t_buy-recipe-splash-[^/]+\//g, '');
};

const RecipeCard = ({
  name,
  subtitle,
  imageSrc,
  imageAlt,
  linkUrl,
  onClick,
  variant = 'medium',
  purchasable,
  'data-testid': testId,
}: RecipeCardProps) => {
  const imageSrcWithoutBuyRecipeSplash = imageSrc ? getImageUrlWithoutBuyRecipeSplash(imageSrc) : undefined;
  const { t } = useTranslation('recipes');

  return (
    <Container $variant={variant} data-testid={testId}>
      <LinkArea href={linkUrl} onClick={onClick}>
        <ImageWrapper $variant={variant}>
          {imageSrcWithoutBuyRecipeSplash && (
            <Image
              loading="lazy"
              src={imageSrcWithoutBuyRecipeSplash}
              alt={imageAlt || name}
              fill
              style={{ objectFit: 'cover', objectPosition: 'top left' }}
            />
          )}
        </ImageWrapper>

        <Content $variant={variant}>
          <Label truncateRows={3}>{name}</Label>
          <CardFooterContent>
            {subtitle}
            {purchasable && (
              <PurchasableIcon
                icon={CartIcon}
                color="black"
                aria-label={t('readyToBuy')}
                mouseoverText={t('readyToBuy')}
                data-testid="recipe-cart-icon"
              />
            )}
          </CardFooterContent>
        </Content>
      </LinkArea>
    </Container>
  );
};

export default RecipeCard;
