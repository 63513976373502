import DELIVERY_METHODS from '@constants/deliveryMethods';
import LINKS from '@constants/links';
import useAppRouter from '@hooks/useAppRouter';
import useCart from '@features/cart/useCart';
import { useCallback } from 'react';

const useOpenDeliveryModal = () => {
  const { cart: { deliveryModeCode = '' } = {} } = useCart();
  const router = useAppRouter();

  const openDeliveryModal = useCallback(() => {
    const link =
      deliveryModeCode !== DELIVERY_METHODS.HOME_CODE ? LINKS.DELIVERY_METHOD_PICKUP : LINKS.DELIVERY_METHOD_HOME;
    router.push({ pathname: router.pathname, query: router.query }, link, { shallow: true, scroll: false });
  }, [deliveryModeCode, router.pathname, router.query]);

  const openDeliveryModalWithAddress = useCallback(
    (address: string) => {
      const query = !!address ? { ...router.query, address: encodeURIComponent(address) } : router.query;
      router.push({ pathname: router.pathname, query }, LINKS.DELIVERY_METHOD_HOME, { shallow: true, scroll: false });
    },
    [deliveryModeCode, router.pathname, router.query]
  );

  return { openDeliveryModal, openDeliveryModalWithAddress };
};

export default useOpenDeliveryModal;
