import { cancelValidateCanGoToCheckout } from '@actions/validators';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import Modal from '@components/molecules/__DEPRECATED__/Modal/index';
import { KEEP_PERSISTENT, KEEP_SESSION, MERGE } from '@constants/cart';
import { useAppDispatch } from '@hooks/redux';
import useHandleCartRestore from '@hooks/useHandleCartRestore';
import useCart from '@features/cart/useCart';
import { mergeModalTracker } from '@trackers';
import Heading from 'components/atoms/__DEPRECATED__/Heading';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { MergeCartModalButton, MergeCartModalButtonsContainer, MergeCartModalStyled } from './MergeCartModal.styles';

export declare type MergeModalActionType = typeof MERGE | typeof KEEP_PERSISTENT | typeof KEEP_SESSION;

interface Props {
  persistedCart: persistedCartType;
  setPersistedCart: (persistedCart: persistedCartType | null) => void;
}
const MergeModal = ({ persistedCart, setPersistedCart }: Props) => {
  const dispatch = useAppDispatch();
  const [closedWithAction, setClosedWithAction] = useState(false);
  const { t } = useTranslation('mergeCart');
  const handleCartRestore = useHandleCartRestore();
  const { cart } = useCart();
  const cartTotalUnitCount = cart?.totalUnitCount ?? 0;

  useEffect(() => {
    mergeModalTracker.trackMergeModalShown();
  }, []);

  useEffect(() => {
    dispatch(cancelValidateCanGoToCheckout());
  }, [dispatch]);

  const closeModal = () => {
    setPersistedCart(null);
  };

  const onCloseModalHandler = () => {
    if (!closedWithAction) {
      mergeModalTracker.trackMergeModalClosed();
    }
    handleCartRestore(KEEP_SESSION);
    closeModal();
  };

  const handleUpdatedCart = (action: MergeModalActionType) => {
    setClosedWithAction(true);
    handleCartRestore(action);
    mergeModalTracker.trackMergeAction(action);
    closeModal();
  };

  return (
    <>
      <Modal isOpen closeHandler={onCloseModalHandler} position="top">
        <MergeCartModalStyled>
          <Heading type="h2">{t('mergeCart->title')}</Heading>
          <Paragraph>
            {t('mergeCart->text->savedCart', {
              count: persistedCart.totalPersistedCartProducts,
              date: persistedCart.persistedCartFormattedDate,
            })}
          </Paragraph>
          <Paragraph>{t('mergeCart->text->actions')}</Paragraph>
          <MergeCartModalButtonsContainer>
            <MergeCartModalButton
              aria-label="merge-carts"
              variant="primary"
              fullWidth
              onClick={() => handleUpdatedCart(MERGE)}
            >
              <Paragraph>
                {t('mergeCart->actions->continueWithMerged', {
                  count: persistedCart.totalPersistedCartProducts + cartTotalUnitCount,
                })}
              </Paragraph>
            </MergeCartModalButton>
            <MergeCartModalButton
              aria-label="keep-session"
              variant="secondary"
              fullWidth
              onClick={() => handleUpdatedCart(KEEP_SESSION)}
            >
              <Paragraph>
                {cartTotalUnitCount > 1
                  ? t('mergeCart->actions->continueWithSession', { count: cartTotalUnitCount })
                  : t('mergeCart->actions->continueWithOneItemSession', { count: cartTotalUnitCount })}
              </Paragraph>
            </MergeCartModalButton>
            <MergeCartModalButton
              aria-label="keep-persistent"
              variant="secondary"
              fullWidth
              onClick={() => handleUpdatedCart(KEEP_PERSISTENT)}
            >
              <Paragraph>
                {persistedCart.totalPersistedCartProducts > 1
                  ? t('mergeCart->actions->continueWithPersistent', {
                      count: persistedCart.totalPersistedCartProducts,
                    })
                  : t('mergeCart->actions->continueWithOneItemPersistent', {
                      count: persistedCart.totalPersistedCartProducts,
                    })}
              </Paragraph>
            </MergeCartModalButton>
          </MergeCartModalButtonsContainer>
        </MergeCartModalStyled>
      </Modal>
    </>
  );
};

export default MergeModal;
