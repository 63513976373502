import { openDeliveryModal } from '@actions/delivery';
import { isProduct, Product, WarningLabel } from '@components/molecules/Product/ProductDataMapper/types';
import PICK_UNIT from '@constants/pickUnit';
import useCart, { UPDATE_CART_CANCELLED } from '@features/cart/useCart';
import { productToImpressionObject } from '@helpers/productHelper';
import { clampQuantity } from '@helpers/quantity';
import { useDeliveryModeReminderDone } from '@hooks/localStorage';
import { useAppDispatch } from '@hooks/redux';
import type { AxfoodCartProductViewModel, AxfoodProductDetailsViewModel } from '@occ/api-client';
import { cartTracker } from '@trackers';
import { ChangeEvent, useEffect, useState } from 'react';
import { handleWarningLabel } from './helpers';

const DELIVERY_MODAL_TRIGGER_THRESHOLD = 3;

interface Props {
  eventListName: string;
  product: AxfoodCartProductViewModel | AxfoodProductDetailsViewModel | Product;
}

/**
 * Keeps a local state - productQuantity connected to the input field
 * Synchronizes the number of products in the cart with the local state
 */

const useProductQuantityWithCart = ({ eventListName, product }: Props) => {
  const isNewProductModel = isProduct(product);
  const dispatch = useAppDispatch();
  const [productQuantity, setProductQuantity] = useState<number | string>(0);
  const [quantityFieldIsActive, setQuantityFieldIsActive] = useState(false);
  const { cart, addProductsToCart, addProductWithDebounce } = useCart();
  const maybeTriggerDeliveryModal = cart && !cart.slot && cart.totalUnitCount >= DELIVERY_MODAL_TRIGGER_THRESHOLD;
  const products = cart?.products ?? [];
  const cartProduct = products.find((p) => p.code === product.code);
  const productIsInCart = !!cartProduct;
  const qtyInCart = cartProduct?.pickQuantity || cartProduct?.quantity || 0;

  const [modalState, setModalState] = useState<WarningLabel>('NONE');
  const [deliveryModeReminderDone, setDeliveryModeReminderDone] = useDeliveryModeReminderDone(false);

  const onQuantityFieldBlur = () => {
    setQuantityFieldIsActive(false);
  };

  const onQuantityFieldFocus = () => {
    setQuantityFieldIsActive(true);
    if (!productQuantity) {
      setProductQuantity('');
    }
  };

  const onQuantityFieldChange = (quantityField: ChangeEvent<HTMLInputElement>) => {
    if (quantityField.target.value === '') {
      setProductQuantity('');
      return;
    }
    const newQuantity = clampQuantity(quantityField.target.valueAsNumber, product.incrementValue || 0);
    updateQuantity(newQuantity);
  };

  // maybe trigger delivery modal reminder
  useEffect(() => {
    if (maybeTriggerDeliveryModal && !deliveryModeReminderDone) {
      dispatch(openDeliveryModal());
      setDeliveryModeReminderDone(true);
    }
  }, [deliveryModeReminderDone, dispatch, maybeTriggerDeliveryModal, setDeliveryModeReminderDone]);

  const [requestId, setRequestId] = useState(0); // Tracks the latest request
  const [latestRequestId, setLatestRequestId] = useState(0); // Tracks the last successful response
  // Synchronize quantity in cart with local state
  useEffect(() => {
    if (requestId === latestRequestId) {
      setProductQuantity(qtyInCart); // Sync with server quantity only if the response is the latest
    }
  }, [qtyInCart, requestId, latestRequestId]);

  const updateQuantity = (qty: number) => {
    setProductQuantity(qty);

    const currentRequestId = requestId + 1;
    setRequestId(currentRequestId);

    const addToCartForm = {
      productCodePost: product.code,
      qty,
      pickUnit: isNewProductModel
        ? product.price.pickUnitName
        : product.productBasketType?.code === 'ST'
          ? PICK_UNIT.PIECES
          : PICK_UNIT.KILOGRAM, //TODO: https://axfood.atlassian.net/browse/B2C-31520
    };

    if (!productIsInCart) {
      setModalState(isNewProductModel ? product.warningLabel : handleWarningLabel(product));
    }

    addProductWithDebounce(addToCartForm)
      .then(() => {
        cartTracker.addOrRemoveProduct(qty, qtyInCart, product, eventListName);

        setLatestRequestId(currentRequestId);
      })
      .catch((e) => {
        // reset state when catching to prevent mismatches
        setRequestId(0);
        setLatestRequestId(0);

        if (e.message === UPDATE_CART_CANCELLED) return;
        if (e?.errorCode === 'product.not.saleable.online') {
          setModalState('NOTSELLABLE');
          return;
        }
        setModalState('GENERAL_ERROR');
      });
  };

  const changeQuantityBy = (qty: number) => {
    const newQuantity = clampQuantity(+productQuantity + qty, qty);
    updateQuantity(newQuantity);
  };

  // removeProductFromCart handles the addToCart promise and tracks the removal on success
  const removeProductFromCart = () => {
    setProductQuantity(0);
    return addProductsToCart([
      {
        productCodePost: product.code,
        qty: 0,
        pickUnit: isNewProductModel
          ? product.price.pickUnitName
          : product.productBasketType?.code === 'ST'
            ? PICK_UNIT.PIECES
            : PICK_UNIT.KILOGRAM, //TODO: https://axfood.atlassian.net/browse/B2C-31520
      },
    ])
      .then(() => {
        cartTracker.removeProductFromCart(
          isNewProductModel ? product.analytics.impressionObject : productToImpressionObject(product),
          0,
          eventListName
        );
      })
      .catch(() => {
        setModalState('GENERAL_ERROR');
      });
  };

  return {
    onValueChange: onQuantityFieldChange,
    onFocus: onQuantityFieldFocus,
    onBlur: onQuantityFieldBlur,
    quantityFieldIsActive,
    modalState,
    setModalState,
    quantity: productQuantity as number,
    changeQuantityBy,
    removeProductFromCart,
  };
};

export default useProductQuantityWithCart;
