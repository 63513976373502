import { getProduct } from '@api';
import { mapHybrisProduct } from '@components/molecules/Product/ProductDataMapper/productDataMapper';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import { FORTY_MINUTES_IN_MS } from '@constants/timers';
import { useAppSelector } from '@hooks/redux';
import useCustomer from '@hooks/useCustomer';
import useCart from '@features/cart/useCart';
import { selectActiveStoreId } from '@selectors/delivery';
import useSWR, { SWRConfiguration } from 'swr';

export const ENDPOINT = '/axfoodcommercewebservices/v2/hemkop/cms/components';

type keyType = {
  endpoint: string;
  productCode: string;
  customerId: string;
  activeStoreId?: string;
  deliveryModeCode: string;
};

const fetcher = ({ productCode }: keyType) => {
  return getProduct(productCode).then((res) => {
    return mapHybrisProduct(res.data);
  });
};

const options: SWRConfiguration = {
  revalidateIfStale: false,
  refreshInterval: FORTY_MINUTES_IN_MS,
  keepPreviousData: true,
};

/**
 * @summary hook for storing the data from a cms components in SWR
 * @param productCode
 */
const useCmsComponentProduct = (productCode?: string) => {
  const { customerId } = useCustomer();
  const activeStoreId = useAppSelector(selectActiveStoreId);
  const { cart: { deliveryModeCode = '' } = {} } = useCart();

  const res = useSWR<Product>(
    !!productCode
      ? {
          productCode,
          endpoint: ENDPOINT,
          customerId,
          activeStoreId,
          deliveryModeCode,
        }
      : null,
    fetcher,
    options
  );

  return {
    ...res,
    data: !res.error ? res.data : undefined,
  };
};

export default useCmsComponentProduct;
