import { closeDeliveryModal, setDeliveryActiveStoreAndCosts } from '@actions/delivery';
import getActiveStore from '@api/getActiveStore';
import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import axios from 'axios';
import logger from 'config/logger';
import { sideNavToggled } from 'redux/slices/sideNav';
import { RootState } from 'store';

const updateStore = async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
  const source = axios.CancelToken.source();
  try {
    const store = await getActiveStore(source.token);
    if (!store.data.external) {
      dispatch(setDeliveryActiveStoreAndCosts(store.data));
    } else {
      throw new Error('Store was external');
    }
  } catch (e) {
    logger.error('Refresh store from app event failed', e);
  }
};

export const deliveryModalClose = (dispatch: Dispatch, router: any) => {
  dispatch(closeDeliveryModal());
  const { pathname, query } = router;
  router.push({ pathname, query }, undefined, { shallow: true, scroll: false });
};

export const closeAssortmentMenu = (dispatch: Dispatch) => {
  dispatch(sideNavToggled(false));
};

const axfoodAppToWebAPI = (dispatch: Dispatch, router: any, refreshCart: any) => {
  return {
    doRefreshCart: () => {
      refreshCart();
    },
    doRefreshDeliveryMethod: () => {
      refreshCart();
    },
    doRefreshBasestore: () => {
      updateStore(dispatch);
    },
    doCloseDeliveryModal: () => {
      deliveryModalClose(dispatch, router);
    },
    doHandleSwitchedTab: () => {
      closeAssortmentMenu(dispatch);
    },

    // TODO: Implement when my-lists- and checkout pages are built.
    doRefreshVouchers: () => false,
    doRefreshShoppingLists: () => false,
    doHandlePurchaseComplete: () => false,
    doRefreshCreditCards: () => false, // willys: cartUpdate. HK angular: cardDoUpdate

    // Functions called from native app - but only used in Hemkop (will generate errors if not on api)
    doHandleHandlaConfig: () => false, // angular: $stateChangeError ax.theme.module
    doNewCouponCheck: () => false, // voucher pop-up? empty action in angular...
  };
};

export default axfoodAppToWebAPI;
